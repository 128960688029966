import React from 'react'

import PropTypes from 'prop-types'

import './you-tube.css'

const YouTube = (props) => {
  return (
    <div className="you-tube-you-tube">
      <img
        style={{marginRight:"5px"}}
        alt={props.pastedImage_alt}
        src={props.pastedImage_src}
        className="you-tube-pasted-image"
      />
      <span className='testimonal-name'>{props.image_text}</span>
    </div>
  )
}

YouTube.defaultProps = {
  pastedImage_src: '/logos/pastedimage-smjb.svg',
  pastedImage_alt: 'logo',
}

YouTube.propTypes = {
  pastedImage_src: PropTypes.string,
  pastedImage_alt: PropTypes.string,
}

export default YouTube
