import React, { useEffect, useRef,useState } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import ActiveUsers from '../components/active-users'
import YouTube from '../components/you-tube'
import Testimonal from '../components/testimonal'
import VideoPlayer from '../components/VideoPlayer'
import './home.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from '../components/Carousel'
import ControlPanel from '../components/ControlPanel'
import {portfolioImg1} from "../components/assests/assest"
import './styles.css';
AOS.init();
const Home = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,  // Duration of the animation
      // Whether animation should happen only once - while scrolling down
    });
  }, []);
  const caption = "Employees";
  const caption2 = "Brands";
  const caption3 = "Accounts Managed";
  const caption4 = "Completed Projetcs";
  const statistic = "20k+";
  const statistic50 = "5k+";
  const statistic100 = "100k+";
  const statics2500 = "25k+"

  const myDivRef = useRef(null);
  const myDivAbout = useRef(null);

  // Click event handler to scroll to the div
  const handleScrollToDiv = () => {
    if (myDivRef.current) {
      myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollAbout = () => {
    if (myDivAbout.current) {
      myDivAbout.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const images = [
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    portfolioImg1,
    // Add more image URLs as needed
  ];
  const [is3D, setIs3D] = useState(true);
  const carouselRef = useRef();

  const toggle3D = () => {
    setIs3D(!is3D);
  };
  const handleClick = () => {
    window.location.href = 'mailto:tecfortify@gmail.com?subject=Hello%20There&body=I%20would%20like%20to%20get%20in%20touch.';
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Tecfortify</title>
        <meta property="og:title" content="Active Online Software Page" />
      </Helmet>
      <section className="home-hero">
        <div className="home-menu">
          <div id="mobile-menu" className="home-mobile-navigation">
            <img
              alt="pastedImage"
              src="/pastedimage-no9b-1500h.png"
              className="home-logo"
            />
            <div className="home-links">
              <span className="Link">Home</span>
              <span className="Link">Services</span>
              <span className="Link">About Us</span>
            </div>
            <div id="close-mobile-menu" className="home-close-mobile-menu">
              <svg viewBox="0 0 804.5714285714286 1024" className="home-icon">
                <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
              </svg>
            </div>
          </div>
          <div className="home-desktop-navigation">
            <nav className="home-centered">
              <div className="home-left">
                <img
                  alt="pastedImage"
                  src="/mainLogo.svg"
                  className="home-logo1"
                />

              </div>
              <div className="home-right">
                <div className="home-links1">
                  <span className="Link">Home</span>
                  <span onClick={handleScrollToDiv} className="Link">Services</span>
                  <span onClick={handleScrollAbout} className="Link">About Us</span>
                </div>

                <div id="open-mobile-menu" className="home-burger-menu">
                  <img
                    alt="pastedImage"
                    src="/pastedimage-yxbd.svg"
                    className="home-mobile-menu-button"
                  />
                </div>
              </div>
            </nav>
          </div>
          <div className="home-menu-code">
           
          </div>
        </div>
        <header className="home-header">
          <h1 className="home-text007">Take Control of Your Business Now</h1>
          <p className="home-text008">
            Unlock the Potential of Your Business with Our Comprehensive Solutions.
            From Streamlining Operations to Enhancing Productivity,
            We Provide the Tools and Expertise
            You Need to Thrive in Today's Competitive Landscape.
          </p>
          <div className="home-get-started1">
            <span onClick={handleClick} className="home-text009">Get started</span>
          </div>
        </header>
        <div className="home-dashboard-preview">
          <div data-aos="zoom-in" className="home-outline">
            {/* <img
              alt="pastedImage"
              // src="/pastedimage-cdo-1000h.png"
              src="/HomeBanner.svg"
              loading="lazy"
              className="home-image"
            /> */}
            <VideoPlayer src="/HomeVid.mp4" playDuration={5} />

          </div>
        </div>
      </section>
      <section className="home-features">
        <div className="home-title">
          <span className="home-text010">
            <span>
              Let&apos;s grow your market
              presence
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>presence</span>
          </span>
          <span className="home-text014">
            <span>
              Elevate your brand and expand your reach with our tailored marketing solutions
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>eiusmod tempor incididunt.</span>
          </span>
        </div>
        <div className="home-cards">
          <div className="home-container1">
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-fii6m-200h.png"
                className="home-icon02"
              />
              <span className="home-text017">Outsourcing</span>
              <span className="home-text018">
                Outsourcing streamlines operations, reduces costs, and accesses specialized skills, fostering competitiveness and growth in today's business landscape
              </span>
            </div>
            <div className="home-publish card">
              <img
                alt="pastedImage"
                src="/pastedimage-mimg-200h.png"
                className="home-icon02"
              />
              <span className="home-text019">Design Studio</span>
              <span className="home-text020">
                Design Studio crafts compelling visual narratives, blending creativity and strategy to elevate brands and captivate audiences effectively
              </span>
            </div>
          </div>
          <div className="home-container2">
            <div className="card home-analyze">
              <img
                alt="pastedImage"
                src="/pastedimage-l6p-200h.png"
                className="home-icon02"
              />
              <span className="home-text021">Software Development</span>
              <span className="home-text022">
                Software Development pioneers innovation, crafting tailored solutions to streamline processes and drive digital transformation efficiently.
              </span>
            </div>
            <div className="card">
              <img
                alt="pastedImage"
                src="/pastedimage-vyi5-200h.png"
                className="home-icon02"
              />
              <span className="home-text023">Social Media Management</span>
              <span className="home-text024">
                Elevating brands through strategic engagement, content curation, and audience growth strategies for impactful online presence
              </span>
            </div>
          </div>
        </div>
      </section>
      <section ref={myDivRef} className="home-integrations">
        <div className="home-centered-container1">
          <div className="home-heading">
            <span className="title">
              We Serve <span style={{ fontWeight: 700 }}>Diverse Industry</span> Verticals
            </span>
            <span className="home-text014">
              With in-depth tech and trend knowledge, our team provides customised
              solutions that tackle each client's
              unique challenges, helping them stay ahead in today's tech landscape.
            </span>
          </div>
          <div className="home-pills-container">
            <div className="home-pills">
              <div className="home-container6">
                <div data-aos="fade-down-right">
                  <YouTube pastedImage_src="/retail.svg" image_text="Retail & E-commerce"></YouTube>
                </div>
                <div data-aos="fade-down-left">
                  <YouTube pastedImage_src="/media.svg" image_text="Media & Entertainment"></YouTube>
                </div>

                {/* <YouTube pastedImage_src="/logos/group-200h.png"></YouTube> */}
              </div>

              <div className="home-container7">
                <div data-aos="fade-right"  >
                  <YouTube pastedImage_src="/travel.svg" image_text="Travel & Tourism"></YouTube>

                </div>
                <div data-aos="zoom-in">
                  <YouTube pastedImage_src="/education.svg" image_text="Education & E-Learning"></YouTube>

                </div>
                <div data-aos="fade-left">
                  <YouTube pastedImage_src="/Finance.svg" image_text="Banking & Finance"></YouTube>

                </div>
                <div data-aos="fade-up">
                  <YouTube pastedImage_src="/healthcare.svg" image_text="Healthcare"></YouTube>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-statistics">
        <div className="home-container3">
          <div style={{ display: 'block' }}>

            <div>
              {statistic.split('').map((char, index) => (
                <span
                  style={{ fontSize: '4rem', color: 'white', fontWeight: '800' }}
                  key={index}
                  data-aos="fade-down"
                  data-aos-delay={(index + 1) * 500} // Start animating after the title
                >
                  {char}
                </span>
              ))}
            </div>
            <div data-aos="fade-down" data-aos-delay="0">
              <h1 style={{ color: 'white', fontWeight: '400' }}>{caption}</h1>
            </div>
          </div>

          <div style={{ display: 'block' }}>

            <div>
              {statistic50.split('').map((char, index) => (
                <span
                  style={{ fontSize: '4rem', color: 'white', fontWeight: '800' }}
                  key={index}
                  data-aos="fade-down"
                  data-aos-delay={(index + 1) * 500} // Start animating after the title
                >
                  {char}
                </span>
              ))}
            </div>

            <div data-aos="fade-down" data-aos-delay="0">
              <h1 style={{ color: 'white', fontWeight: '400' }}>{caption2}</h1>
            </div>
          </div>
          <div style={{ display: 'block' }}>

            <div>
              {statistic100.split('').map((char, index) => (
                <span
                  style={{ fontSize: '4rem', color: 'white', fontWeight: '800' }}
                  key={index}
                  data-aos="fade-down"
                  data-aos-delay={(index + 1) * 500} // Start animating after the title
                >
                  {char}
                </span>
              ))}
            </div>

            <div data-aos="fade-down" data-aos-delay="0">
              <h1 style={{ color: 'white', fontWeight: '400' }}>{caption3}</h1>
            </div>
          </div>
          <div style={{ display: 'block' }} className='centered'>

            <div>
              {statics2500.split('').map((char, index) => (
                <span
                  style={{ fontSize: '4rem', color: 'white', fontWeight: '800' }}
                  key={index}
                  data-aos="fade-down"
                  data-aos-delay={(index + 1) * 500} // Start animating after the title
                >
                  {char}
                </span>
              ))}
            </div>

            <div data-aos="fade-down" data-aos-delay="0">
              <h1 style={{ color: 'white', fontWeight: '400' }}>{caption4}</h1>
            </div>
          </div>


        </div>
      </section>
      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container4">
            <div className="home-left1">
              {/* <span className="sub-title">Content Management</span> */}
              <span className="home-text028 title">
                Manage all your Social platforms in just one place.
              </span>
              <span className="home-text029">
                Discover the power of streamlined social management with our intuitive platform. Effortlessly schedule posts, engage with your audience, and track performance metrics—all from one convenient dashboard.
                Take your social presence to the next level with ease.
              </span>
              <div className="home-get-started2 template-button">
                <span onClick={handleClick} className="home-text030">Get started</span>
              </div>
            </div>
            <div className="home-image-container">
              <img
                alt="pastedImage"
                src="/pastedimage-wvmq%201-1200w.svg"
                className="home-cards-image"
              />
            </div>
          </div>
        </div>
        {/* <div className="home-banner-advanced-analytics">
          <div className="home-centered-container">
            <div className="home-image-container1">
              <img
                alt="pastedImage"
                src="/test-1200w.png"
                className="home-cards-image1"
              />
            </div>
            <div className="home-right1">
              <span className="sub-title">
                Reporting Metrics
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <h2 className="home-text032 title">
                Advanced analytics, easy to understand.
              </h2>
              <div className="home-category">
                <span className="home-text033">Power reports</span>
                <span className="home-text034">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  vv
                </span>
                <span className="home-text035">Example -&gt;</span>
              </div>
              <div className="home-category1">
                <span className="home-text036">Power reports</span>
                <span className="home-text037">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  vv
                </span>
                <span className="home-text038">Example -&gt;</span>
              </div>
            </div>
          </div>
        </div> */}
        <div className="home-banner">
          <div className="home-container5">
            <div className="home-left2">
              <h2 className="home-text040 title">
                Let's Have look on some Portfolios
              </h2>
              <span className="home-text041">
                Explore a curated collection of our latest projects and works.
                From web design to functionality,
                discover the diverse range of creative endeavors showcased in our portfolios.
              </span>
            
              <div className={`wrapper ${is3D ? 'threeD' : 'twoD'}`}>
                <section id="container" className={is3D ? 'threeD' : 'twoD'}>
                <ControlPanel carouselRef={carouselRef} toggle3D={toggle3D} />
                </section>
              
              </div>
             
            </div>
            <div className="home-image-container2">
              <div className={`wrapper ${is3D ? 'threeD' : 'twoD'}`}>
                <section id="container" className={is3D ? 'threeD' : 'twoD'}>
                  <Carousel images={images} ref={carouselRef} />
                </section>
              
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className="home-testimonals">
        <div className="home-left3">
          <span className="home-text091 title">
            What people say about Tecfortify
          </span>
        </div>
        <div className="home-right2">
          <div className="home-column">
            <Testimonal
              Quote='"Pellentesque sodales libero nulla, ac imperdiet ante tincidunt vel. Fusce auctor augue sed massa finibus, et dictum sem mollis. In luctus.”'
              Avatar_src="/image552912-e3yq-200h.png"
            ></Testimonal>
            <Testimonal
              From="Social Club"
              Name="Jessica Smith"
              Quote="“Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.\u2028 Inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.”"
              Avatar_src="/image312912-mvsg-200h.png"
            ></Testimonal>
            <Testimonal
              From="BeMe"
              Name="Logan Boy"
              Quote="“Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo..”"
              Avatar_src="/image502912-6wy-200h.png"
            ></Testimonal>
            <Testimonal
              From="Hello W."
              Name="Laraine Summers"
              Quote="“Rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae.”"
              Avatar_src="/image202912-zekh-200h.png"
            ></Testimonal>
          </div>
          <div className="home-column1">
            <Testimonal
              From="Handsly"
              Name="William McPau"
              Quote='"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”'
              Avatar_src="/image572912-0d3-200h.png"
            ></Testimonal>
            <Testimonal
              From="Share"
              Name="Mariah Mae"
              Quote="“Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.”"
              Avatar_src="/image632913-swij-200h.png"
            ></Testimonal>
            <Testimonal
              From="Gather"
              Name="John Finati"
              Quote='"Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”'
              Avatar_src="/image102913-x4z8-200h.png"
            ></Testimonal>
            <Testimonal
              From="Zigo"
              Name="Mary Pau"
              Quote='"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ”'
              Avatar_src="/image562913-ycff-200h.png"
            ></Testimonal>
          </div>
        </div>
        <span className="home-text092">
          <span>Show more</span>
          <br></br>
        </span>
      </section> */}
      <section className="home-action-bar">
        <div className="home-action">
          <div className="home-heading3">
            <span className="home-text095 title">
              <span>Get leads now </span>
              <br></br>
              <span>with Tecfortify!</span>
            </span>
            <span className="home-text099">
              Take the lead in your industry and unlock new opportunities for growth with our innovative services.
            </span>
            {/* <div className="home-get-started8 template-button">
              <span className="home-text100">
                <span>Start free</span>
                <br></br>
              </span>
            </div> */}
          </div>
          <div className="home-images">
            {/* <img
              alt="image"
              src="/e564eaa3c4fab71792794d666a281742-1200w.png"
              className="home-image1"
            /> */}
          </div>
        </div>
      </section>
      <section className="home-faq">
        <div className="home-heading4">
          <span className="home-text103 title">Frequently asked questions</span>
          <span className="home-text104">
            Find answers to common queries and concerns in our Frequently
            Asked Questions section. Whether you're seeking clarification on our services,
            policies, or procedures, browse through our FAQ for helpful information and solutions
          </span>
        </div>
        <div className="home-accordion">
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header1">
              <span className="home-text105">
                — What types of software and web applications do you develop?
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text106">
                We specialize in developing custom software tailored to your specific needs,
                ranging from web applications,
                mobile apps, enterprise solutions,
                to CRM systems and more.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div className="accordionContainer">
            <div className="home-header2">
              <span className="home-text107">
                — How do you ensure the security of the software and applications you develop?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text108">
                Security is our top priority. We follow industry best practices such as regular code reviews, encryption protocols, and stringent testing to ensure the integrity and confidentiality of your data.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header3">
              <span className="home-text109">
                — Can you provide examples of your past projects?
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text110">
                Absolutely! We have successfully completed projects across various industries including healthcare, finance, e-commerce, and education. Feel free to explore our portfolio to see examples of our work.
                For Further information of project mail us.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header4">
              <span className="home-text111">
                — What technologies do you use in your development process?
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text112">
                We utilize a wide range of technologies including but not limited to JavaScript frameworks like React and Angular, backend frameworks like Node.js and Django, as well as databases such as MySQL, MongoDB, and PostgreSQL.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
          <div data-faq="closed" className="accordionContainer">
            <div className="home-header5">
              <span className="home-text113">
                — Do you offer ongoing support and maintenance for the software after it's developed?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text114">
                Yes, we provide comprehensive support and maintenance services to ensure that your software remains up-to-date, secure, and optimized for performance even after deployment.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>

          <div data-faq="closed" className="accordionContainer">
            <div className="home-header5">
              <span className="home-text113">
                — How do you handle scalability and future growth requirements of the software?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text114">
                Our development process is scalable and flexible, allowing us to accommodate your evolving business needs. We design our solutions with scalability in mind, ensuring that they can grow seamlessly along with your business.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>

          <div data-faq="closed" className="accordionContainer">
            <div className="home-header5">
              <span className="home-text113">
                —Do you provide staffing solutions for IT positions?
              </span>
              <svg viewBox="0 0 1024 1024" className="accordionIcon">
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
              </svg>
            </div>
            <div className="accordionContent">
              <span className="home-text114">
                Yes, in addition to software development, we offer staffing solutions for various IT positions including developers, designers, project managers, and quality assurance professionals. Whether you need short-term contractors or long-term placements, we can help you find the right talent for your team
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="home-accordion-code">
          <Script
            html={`<script>
// Accordion - Code Embed

const accordionContainers = document.querySelectorAll(".accordionContainer"); // All accordion containers
const accordionContents = document.querySelectorAll(".accordionContent"); // All accordion content
const accordionIcons = document.querySelectorAll(".accordionIcon"); // All accordion icons

accordionContents.forEach((accordionContent) => {
    accordionContent.style.display = "none"; //Hides all accordion contents
});

accordionContainers.forEach((accordionContainer, index) => {
    accordionContainer.addEventListener("click", () => {
        accordionContents.forEach((accordionContent) => {
            accordionContent.style.display = "none"; //Hides all accordion contents
        });

        accordionIcons.forEach((accordionIcon) => {
            accordionIcon.style.transform = "rotate(0deg)"; // Resets all icon transforms to 0deg (default)
        });

        if (accordionContainer.classList.contains("accordion-open")) { // Checks if this container has class "accordion-open"
            accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open"
        } else {
            accordionContainers.forEach((accordionContainer) => {
                accordionContainer.classList.remove("accordion-open"); // Removes class "accordion-open" from all containers
            });

            accordionContainer.classList.add("accordion-open"); // Adds class "accordion-open" to this container
            accordionContents[index].style.display = "flex"; // Shows accordion content
            accordionIcons[index].style.transform = "rotate(180deg)"; // Rotates accordion icon 180deg
        }
    });
});

/*
Here's what the above is doing:
    1. Selects all accordion containers, contents, and icons
    2. Hides all accordion contents
    3. Adds an event listener to each accordion container
    4. When an accordion container is clicked, it:
        - Hides all accordion contents
        - Resets all icon transforms to 0deg (default)
        - Checks if this container has class "accordion-open"
            - If it does, it removes class "accordion-open"
            - If it doesn't, it:
                - Removes class "accordion-open" from all containers
                - Adds class "accordion-open" to this container
                - Shows accordion content
                - Rotates accordion icon 180deg
*/
</script>`}
          ></Script>
        </div>
      </section>
      <footer className="homeheader">

        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }} className='mediaquearyfooter'>
          <div ref={myDivAbout} >
            <div>
              {/* <div style={{ display: "flex",justifyContent:'flex-start',gap:"1rem" }} className='logocenterme'>
                <img
                  alt="pastedImage"
                  src="/logoWhite.png"
                  className="home-branding"
                  style={{ width: "30px", height: "30px" }}

                />
                <p className='texttecfortify' style={{ color:"white", }}>Tecfortify</p>
              </div> */}
              <p className='textstyles'>Tecfortify is an award winning Custom Software <br />Development Company Based On Remote Work, In India.</p>
              <p className='textstyles '>tecfortify@gmail.com</p>
              <p className='textstyles'>Call Us: +91 8700803722</p>

            </div>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: '2rem' }} className='mediaquearyfooter1'>
              {/* <p className='textstyles textstylese'>Privacy Policy</p>
              <p className='textstyles textstylese'>terms and condition</p> */}
              <p className='textstyles textstylese'>@2024 Tecfortify. All rights reserved.</p>
            </div>

          </div>

          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4rem" }}>
            <div style={{ color: "#ffffff" }}>
              <h1 className='textstyles texttecfortify'> Services We Offer</h1>
              <p className='textstyles'>Custom Software</p>
              <p className='textstyles'>Outsourcing</p>
              <p className='textstyles'>Design Studio</p>
              {/* <p className='textstyles'>Join Our Team</p> */}
            </div>




            <div style={{ color: "#ffffff" }}>
              <h1 className='textstyles texttecfortify'> Who We Are</h1>
              <p className='textstyles'>About Us</p>
              <p className='textstyles'>News</p>
              <p className='textstyles'>Tech Blogs</p>
              {/* <p className='textstyles'>Contact Us</p> */}
            </div>
          </div>
        </div>



        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "4rem", color: "#ffffff", flexDirection: "column" }}>

          <img
            alt="pastedImage"
            src="/logoWhite.png"
            className="home-branding"
          />


          <div>Made with ❤️ by Tecfortify</div>
        </div>

      </footer>
    </div>

    // adding some lines



  )
}

export default Home
