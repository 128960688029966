import React, { useState } from 'react';
import '../../src/views/home.css'
const ControlPanel = ({ carouselRef, toggle3D }) => {
  const [nodeCount, setNodeCount] = useState(5);
  const [position, setPosition] = useState(0);

  const handleNodeCountChange = (e) => {
    const count = parseInt(e.target.value, 5);
    setNodeCount(count);
    carouselRef.current.setItemCount(count);
  };

  const handlePositionChange = (e) => {
    const pos = parseInt(e.target.value, 5);
    setPosition(pos);
    carouselRef.current.move(pos);
  };

  return (
    <section id="ctrls">
      <div id="dots"></div>
      <div>
        {/* <div>
          Total: <label id="nodeCountLbl">{nodeCount}</label>
          <input
            id="nodeCountCtrl"
            type="number"
            value={nodeCount}
            onChange={handleNodeCountChange}
            min="2"
            max="20"
          />
        </div> */}
        {/* <div>
          Position:<label id="posCtrlLbl">Position</label>
          <input
            id="posCtrl"
            type="range"
            value={position}
            onChange={handlePositionChange}
            min="0"
            max={nodeCount - 1}
          />
        </div> */}
      </div>
      <div style={{width: '210px',
    marginTop: '-13.5rem',
    marginLeft: '-8rem',
    cursor:'pointer'}}  className='home-get-started2 template-button ' onClick={() => carouselRef.current.toggleAxis()}>
        {/* <button id="prev" onClick={() => { carouselRef.current.move(position - 1); setPosition(position - 1); }}>Previous</button>
        <button id="next" onClick={() => { carouselRef.current.move(position + 1); setPosition(position + 1); }}>Next</button> */}
        <button style={{color:'white'}}   id="axisCtrl" >Change Your Axis</button> 
      </div>
    </section>
  );
};

export default ControlPanel;
