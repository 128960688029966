import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import $ from 'jquery'; 
const Carousel = forwardRef((props, ref) => {
  const [items, setItems] = useState([]);
  const [axis, setAxis] = useState('x');
  const [pos, setPos] = useState(0);
  const [radius, setRadius] = useState(0);
  const [displayBackface, setDisplayBackface] = useState(true);
  const [slideCount, setSlideCount] = useState(0);
  const carouselRef = useRef(null);
  const intervalRef = useRef(null);

  const rotations = { x: 'rotateX', y: 'rotateY' };
  const rotOpp = { x: 'y', y: 'x' };

  useImperativeHandle(ref, () => ({
    toggleAxis: () => {
      setAxis(rotOpp[axis]);
      setPos(0);
      morph();
    },
    toggleBackface: () => {
      setDisplayBackface(!displayBackface);
    },
    move: (n) => {
      const newPos = axis === 'y' ? ~n + 1 : n;
      setPos(newPos);
      morph();
    },
    setItemCount: (count) => {
      setItems([...Array(count).keys()]);
    },
  }));

  useEffect(() => {
    setItems([...Array(8).keys()]); // Initial items
  }, []);

  useEffect(() => {
    morph();
  }, [items, axis, pos, radius]);

  const morph = () => {
    const nodeSize = axis === 'x' ? carouselRef.current.offsetHeight : carouselRef.current.offsetWidth;
    const nodeSlice = 360 / items.length;
    setRadius(Math.round((nodeSize / 2) / Math.tan(Math.PI / items.length)));

    items.forEach((_, i) => {
      const ang = nodeSlice * i;
      const transformStyle = `${rotations[axis]}(${ang * (axis === 'y' ? 1 : -1)}deg) translateZ(${radius}px)`;
      $(`#item-${i}`).css({
        display: 'block',
        transform: transformStyle,
        // backgroundColor: `hsla(${ang}, 100%, 50%, 1)`,
      });
      $(`#item-${i}`).one('click', () => {
        console.log(`Clicked slide with ID: ${i}`);
      });
    });

    for (let i = items.length; i < 20; i++) {
      $(`#item-${i}`).css({ display: 'none' });
    }

    const currentTransform = `translateZ(-${radius}px) ${rotations[axis]}(${360 / items.length * pos}deg)`;
    $(carouselRef.current).css({ transform: currentTransform });
  };

  const startAutoSlide = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setPos((prevPos) => {
        const newPos = (axis === 'x' ? prevPos + 1 : prevPos - 1) % items.length;
        setSlideCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount % 2 === 0) {
            setAxis(rotOpp[axis]);
            morph();
          }
          return newCount;
        });
        morph();
        return newPos;
      });
    }, 1700);
  };
 
  
  
  const stopAutoSlide = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  return (
    <div
      className={`carousel ${displayBackface ? '' : 'backface'}`}
      ref={carouselRef}
      onMouseEnter={startAutoSlide}
      onMouseLeave={stopAutoSlide}
    >
      {props.images.map((item, index) => (
        <div key={index} id={`item-${index}`} className="carousel-item">
         <img src={item}/>
        </div>
      ))}
    </div>
  );
});

export default Carousel;
