import React, { useEffect, useRef } from 'react';
import './VideoPlayer.css';  // Import the CSS file

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();
    }
  }, []);

  return (
    <video ref={videoRef} src={src} className="responsive-video" loop autoPlay muted />
  );
};

export default VideoPlayer;
